@use "/styles/base";

.container {
  max-width: 1298px;
  width: 100%;
  margin: 1em auto;
  padding: 0 2em;

  table {
    max-width: 100%;
    min-width: 500px;
    margin: 0 auto;
  }

  tr {
    margin-top: 0.5em;
  }

  th,
  td {
    padding: 0.5rem;
    vertical-align: baseline;
  }

  .note-th {
    max-width: 20em;
    min-width: 10em;
    text-align: left;
  }

  .note-td {
    max-width: 20em;
    min-width: 10em;
    word-wrap: break-all;
    overflow-wrap: break-word;
  }

  .align-left {
    text-align: left;
  }

  .button {
    padding: 0.5em 1em;
  }
}

.empty {
  margin: 2em;
  text-align: center;
  font-size: 1.25em;
}
