.picker {
  display: flex;
  flex-direction: column;
  gap: 1em;

  .input {
    display: flex;
    gap: 1em;
  }

  .triangle {
    &::after {
      content: "";
      width: 0px;
      height: 0px;
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      margin-left: 10px;
      margin-bottom: 2px;
      border-style: solid;
      border-width: 4px 6px 4px 0px;
      border-color: transparent #6a6a7b transparent transparent;
      border-radius: 1px;
      transition: all 200ms ease-out;
    }

    &.is-expanded {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }
}
