.details {
  display: flex;
  flex-direction: column;
  gap: 3em;

  margin: 0.5em;
  margin-left: 2em;

  .title {
    font-size: 1.5em;
  }

  .form {
    width: 100%;

    .col {
      display: flex;
      flex-direction: column;
      gap: 1em;
    }
  }

  .buttons {
    display: flex;
    gap: 1em;
    margin-top: 3em;
  }

  .thumbnail {
    max-width: 200px;
    border: 1px solid lightblue;
  }

  .video {
    width: 100%;
    max-width: 30em;
  }

  .compact-control {
    input {
      min-width: unset;
    }
  }
}
