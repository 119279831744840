.section {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  padding: 2em;

  .header {
    display: flex;

    .title {
      font-size: 1.5em;
      font-weight: 500;
    }

    .info {
      color: blue;
      padding: 0 0.25em;
    }

    .btn {
      padding: 0.5em 2em;
      margin-left: 2em;
    }
  }

  .error {
    margin-top: 30%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title {
      font-size: 2em;
    }

    .content {
      font-size: 1em;
      margin-top: 2em;
    }
  }
}
