$color-primary: #ee7720;
$color-secondary: #7e9bc4;

$color-bg: #7e9bc4;

$color-light: rgba(#8c8c8c, 23%);

$text-color: #363942;
$text-color: #fff;

$space-small: 1rem;
$space-medium: 2rem;
$space-large: 3rem;

$breakpoint-lg: 980px;
$breakpoint-md: 750px;
$breakpoint-sm: 500px;
$breakpoint-x-sm: 375px;

$container-width-sm: 750px;
$container-width-md: 980px;
$container-width-lg: 1190px;
$container-width-fluid: 100%;

$container-width: 1298px;

$site-max-width: 1600px;

@mixin content-container($max-width: $container-width, $padding: 0 2rem) {
  max-width: $max-width;
  width: 100%;
  margin: 0 auto;
  padding: $padding;
}

@mixin button($bg-color: $color-primary, $padding: 0.5em 1.3em, $radius: 1em) {
  background-color: $bg-color;
  padding: $padding;
  border-radius: $radius;
  outline: none;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darken($color: $bg-color, $amount: 10%);
  }
  &:active {
    background-color: darken($color: $bg-color, $amount: 20%);
  }
}
