@use "/styles/base";

.page {
  min-width: 960px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
}

.header {
  display: flex;
  gap: 1em;
  padding: 0.5em 1em;
  color: white;
  background-color: base.$color-bg;

  .logo {
    height: 2.5em;
    margin: 0 2em 0 0.5em;
  }

  .nav {
    flex: 1 0 auto;
    display: flex;
    align-items: center;
    gap: 1em;

    a {
      text-decoration: none;
    }

    .link {
      color: rgba(white, 0.9);
    }

    .active-link {
      color: rgba(white, 0.9);
      font-size: 1.2em;
      padding: 0.5rem 0.8rem;
      background-color: base.$color-primary;
    }
  }

  .member {
    display: flex;
    align-items: center;

    .logout {
      padding: 0.5em 1em;
      margin: 0 0.5em;
    }
  }
}

.main {
  flex: 1 1 auto;
  display: flex;
}

.footer {
  .content {
    color: white;
    background-color: base.$color-bg;
    text-align: center;
    padding: 1em;
    width: 100%;
  }
}
