@use "/styles/base";

.container {
  display: flex;
  height: 100%;
  width: 100%;

  .content {
    flex: 1 1 auto;
    padding: 1em;
  }
}

.sidebar {
  flex: 0 0 auto;
  padding: 2em 0;
  background-color: lighten(base.$color-bg, 20%);
  height: 100%;

  .nav {
    display: flex;
    flex-direction: column;

    a {
      padding: 0.5em 2em;
      text-decoration: none;
      letter-spacing: 0.25em;
    }

    .active-link {
      font-weight: bold;
      color: white;
      background-color: base.$color-primary;
    }

    .link {
      color: rgba(black, 0.9);
    }
  }
}
